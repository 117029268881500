<template>
	<div style="height: 100%;">
		<div class="pc-main">
			<div class="main-table">
				<el-form :inline="true" :size="theme.size">
					<el-form-item>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" @change="getClients(params)" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
				</el-form>
				<div>
					<el-table class="scroll-wrapper ssss absolute" :max-height="(theme.height - 160)+'px'" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#000'}" :cell-style="cellStyle" :data="clients" :size="theme.size" @sort-change="sortChange" :summary-method="getSummaries" show-summary>
						<!-- <el-table-column label="隶属部门" prop="department" min-width="81"></el-table-column> -->
						<el-table-column label="人员" prop="name" min-width="87"></el-table-column>
						<el-table-column label="客户量">
							<el-table-column label="订单" prop="orders" min-width="81" sortable="custom"></el-table-column>
							<el-table-column label="线索" prop="clues" min-width="81" sortable="custom"></el-table-column>
							<el-table-column label="公海" prop="seas" min-width="81" sortable="custom"></el-table-column>
							<el-table-column label="合计" prop="clients" min-width="81" sortable="custom"></el-table-column>
						</el-table-column>
						<el-table-column label="添加量" prop="added" min-width="81" sortable="custom"></el-table-column>
						<el-table-column label="成交量" prop="dealed" min-width="81" sortable="custom"></el-table-column>
						<el-table-column label="添加率" prop="add_rate" min-width="81" :formatter="({clients, added}) => { return ((added/clients)*100).toFixed(2)-0; }"></el-table-column>
						<el-table-column label="成交率" prop="deal_rate" min-width="81" :formatter="({clients, dealed}) => { return ((dealed/clients)*100).toFixed(2)-0; }"></el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<style type="">
	.ssss .el-table__footer-wrapper tbody td.el-table__cell {
		background-color: #c7000a;
		font-weight: 600;
		font-size: 13px;
		color: #fff;
	}
</style>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			cellStyle ({row}) {
				if (row.name == '公海') return {color: '#c7000a', fontWeight: 900};
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getClients(this.params);
			},
			async getClients (params, loading = false) {
				const { date } = params;
				// 时间需要转换，否则有时差
				const res = await this.$http.get(this.$api.URI_STATISTICS, {params: {...params, date: [this.$moment(date[0]).format('YYYY-MM-DD HH:mm:ss'), this.$moment(date[1]).format('YYYY-MM-DD HH:mm:ss')], action: 'yingxiao'}, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.clients = result;
			},
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '合计';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
					} else {
						sums[index] = 'N/A';
					}
					sums[5] = ((sums[3]/sums[1])*100).toFixed(2);
					sums[6] = ((sums[4]/sums[1])*100).toFixed(2);
				});
				return sums;
			}
		},
		data() {
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			return {
				clients: [],
				params: {
					date: [start, new Date()]
				}
			}
		},
		async created () {
			this.getClients(this.params, true);
		}
	};
</script>